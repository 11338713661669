import { Injectable } from "@angular/core";
import { UserService } from "../Service/user.service";
import {
  LoginForm,
  UserModel,
  Users,
  StateModel,
  RegionModel,
} from "../Models/login-form";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Rewards, WeeksBasedEntriesData } from "../Models/rewards";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UserProvider {
  public errorMessage: string;
  public loggedInUser: UserModel = new UserModel();
  public campaignRewards: Rewards;
  public rewardEntriesData: WeeksBasedEntriesData;
  loading: boolean;
  public users: Users[];
  public states: StateModel[];
  public regions: RegionModel[];
  loginName: BehaviorSubject<any> = new BehaviorSubject("");

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  userLogin(LoginForm: LoginForm): void {
    this.errorMessage = "";
    this.userService.userLogin(LoginForm).subscribe(
      (response) => {
        if (response.success) {
          localStorage.setItem("access-token", response.data.access_token);
          localStorage.setItem("userRole", response.data.user_role);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("userName", response.data.user_name);
          this.loginName.next(localStorage.getItem("userName"));
          let user = response.data;
          this.loading = true;
          this.loggedInUser.Token = user.access_token;
          this.loggedInUser.isAuditor = false;
          this.loggedInUser.isModerator = true;
          if (response.data.user_role == 10) {
            this.router.navigate(['/tl-entries/tl-entries-data/']);
          } else if (response.data.user_role == 11) {
            this.router.navigate(['/agency-entries/agency-entries-data/']);
          } else if (response.data.user_role == 12) {
            this.router.navigate(['/admin-entries/admin-entries-data/']);
          } else {
            this.toastr.error(
              `Sorry, You don't have access to this module`,
              "Menu Programs",
              {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            localStorage.clear();
            this.loading = false;
            return;
          }
          this.toastr.success("Logged in Successfully", "Success");
        } else {
          localStorage.removeItem("access-token");
          localStorage.removeItem("userRole");
          localStorage.removeItem("userId");
        }
      },
      (error) => {
        if (error.status == 422) {
          this.toastr.error(" Incorrect username or password ", "Error");
          this.errorMessage = " Incorrect username or password ";
          this.loading = false;
        }
      }
    );
  }

  updateNotificationsCount(id) {
    this.userService.getAllNotification(id).subscribe((data) => {});
  }

  getUserList(): void {
    this.userService.getAllUsers().subscribe((data) => {
      this.users = data;
    });
  }

  getStateList(): void {
    this.userService.getAllStates().subscribe((data) => {
      this.states = data;
    });
  }

  getRegionList(): void {
    this.userService.getAllRegions().subscribe((data) => {
      this.regions = data;
    });
  }
}
