import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./Components/layout/layout.component";
import { AuthGuard } from "./Shared/AuthGuard";
const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./Components/login/login.module#LoginModule",
      },
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren:
          "./Components/TL-Entries/tl-entries.module#TLEntriesModule",
      },
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren:
          "./Components/Agency-Entries/agency-entries.module#AgencyEntriesModule",
      },
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren:
          "./Components/Admin-Entries/admin-entries.module#AdminEntriesModule",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
