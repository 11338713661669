import { Injectable } from '@angular/core';
import { UserProvider } from './UserProvider';
import { City, Week, Category } from '../Models/rewards';
import { UserService } from '../Service/user.service';
import { WeekEnttries, RatingModel, RatingParameter, Parameters, RankModel } from '../Models/WeekEntries';

@Injectable({
  providedIn: 'root'
})
export class WeekEntriesProvider {

  selectedEntries: WeekEnttries;
  weekEntries: WeekEnttries[] = [];
  allWeeksEntries: WeekEnttries[] = [];
  cities: City[];
  weeks: Week[];
  position: any[];
  category: Category[];
  ratings: RatingModel[];
  entriesRating: RatingModel[];
  ratingParameters: RatingParameter[];
  rank: RankModel[] = [];
  constructor(public userService: UserService, private userProvider: UserProvider,
    private weekEntriesProvider: WeekEntriesProvider) {
  }

  getWeekEntries(): void {
    this.userService.getWeekEntriesData().subscribe(
      (response) => {
        if (response.success)
          this.weekEntries = response.data;
        let userRole = localStorage.getItem('userRole');
        let user = localStorage.getItem('userId');
        if (parseInt(userRole) === 70) {
          this.weekEntries = this.weekEntries.filter(w => w.user_id == parseInt(user));
        }

        if (this.weekEntries.length) {
          this.weekEntries.forEach(
            e => {
              e.isConfirmed = false;
              e.isShortlisted = false;
              e.isRejected = false;
              e.notShortlist = false;
              if (e.moderator_internal_status == 9) {
                e.isConfirmed = true;
              }

              if (e.moderator_internal_status == 4) {
                e.isShortlisted = true;
              }

              if (e.status == 2) {
                e.isHolded = true;
              }

              if (e.moderator_internal_status == 6) {
                e.isRejected = true;
              }


              if (e.moderator_internal_status == 9) {
                // let Rank: RankModel = new RankModel;
                // Rank.rank = e.rank;
                // this.rank.push(Rank);
              }

              e.isPDF = false;

              if (e.data_path) {
                let path = e.data_path.split('.');
                console.log(path);
                if (path[path.length - 1] == 'pdf') {
                  e.isPDF = true;
                }
              }

              let sumRating = 0;
              e.rating.forEach(
                r => {
                  sumRating += r.rating;
                }
              )
              e.ratingvalue = sumRating;
            }
          )
        }
      }
    )
  }

  UpdateWeekEntry(weekEntry: WeekEnttries): void {
    this.userService.updateWeekEntry(weekEntry).subscribe(
      (response) => {
        this.getWinnersEntries();
        this.getWeekEntries();
        if (response.success) {
          this.allWeeksEntries.forEach(
            w => {
              if (w.id == weekEntry.id) {
                w.isHolded = weekEntry.isHolded;
                w.isRejected = weekEntry.isRejected;
                w.isShortlisted = weekEntry.isShortlisted;
                w.moderator_internal_status = weekEntry.moderator_internal_status;
                w.points = weekEntry.points;
                w.rating = weekEntry.rating;
                w.ratingvalue = weekEntry.ratingvalue;
                w.status = weekEntry.status;
                w.submitted_date = weekEntry.submitted_date;
                w.title = weekEntry.title;
                w.user_id = weekEntry.user_id;
                w.winningtime = weekEntry.winningtime;
                w.comments = weekEntry.comments;
                if (w.moderator_internal_status == 9) {
                  w.isConfirmed = true;
                }

                if (w.moderator_internal_status == 4) {
                  w.isShortlisted = true;
                  w.notShortlist = false;
                }

                if (w.status == 2) {
                  w.isHolded = true;
                }

                if (w.moderator_internal_status == 6) {
                  w.isRejected = true;
                }

                w.isPDF = false;

                if (w.data_path) {
                  let path = w.data_path.split('.');
                  console.log(path);
                  if (path[path.length - 1] == 'pdf') {
                    w.isPDF = true;
                  }
                }
              }
            }
          )

          this.weekEntries.forEach(
            w => {
              if (w.id == weekEntry.id) {
                w.isHolded = weekEntry.isHolded;
                w.isRejected = weekEntry.isRejected;
                w.isShortlisted = weekEntry.isShortlisted;
                w.moderator_internal_status = weekEntry.moderator_internal_status;
                w.points = weekEntry.points;
                w.rating = weekEntry.rating;
                w.ratingvalue = weekEntry.ratingvalue;
                w.status = weekEntry.status;
                w.submitted_date = weekEntry.submitted_date;
                w.title = weekEntry.title;
                w.user_id = weekEntry.user_id;
                w.winningtime = weekEntry.winningtime;
                w.comments = weekEntry.comments;
                w.isPDF = false;

                if (w.data_path) {
                  let path = w.data_path.split('.');
                  console.log(path);
                  if (path[path.length - 1] == 'pdf') {
                    w.isPDF = true;
                  }
                }

                if (w.moderator_internal_status == 9) {
                  w.isConfirmed = true;
                }

                if (w.moderator_internal_status == 4) {
                  w.isShortlisted = true;
                  w.notShortlist = false;
                }

                if (w.status == 2) {
                  w.isHolded = true;
                }

                if (w.moderator_internal_status == 6) {
                  w.isRejected = true;
                }
              }
            }
          )

        }
      }
    )


  }

  getWinnersEntries() {
    this.userService.getWeekEntriesData()
      .subscribe(
        (response) => {
          if (response.success) {
            this.allWeeksEntries = response.data;
            let userRole = localStorage.getItem('userRole');
            let user = localStorage.getItem('userId');
            if (parseInt(userRole) === 70) {
              this.allWeeksEntries = this.allWeeksEntries.filter(w => w.user_id == parseInt(user));
            }
            if (this.allWeeksEntries.length) {
              this.allWeeksEntries.forEach(
                e => {
                  e.isConfirmed = false;
                  e.isShortlisted = false;
                  e.isRejected = false;
                  e.isHolded = false;
                  e.notShortlist = false;
                  if (e.moderator_internal_status == 9) {
                    e.isConfirmed = true;
                    e.editRating = true;
                  }

                  if (e.moderator_internal_status == 4) {
                    e.isShortlisted = true;
                  }

                  if (e.status == 2) {
                    e.isHolded = true;
                  }

                  if (e.moderator_internal_status == 6) {
                    e.isRejected = true;
                  }


                  e.isPDF = false;

                  if (e.data_path) {
                    let path = e.data_path.split('.');
                    console.log(path);
                    if (path[path.length - 1] == 'pdf') {
                      e.isPDF = true;
                    }
                  }


                  let sumRating = 0;
                  e.rating.forEach(
                    r => {
                      sumRating += r.rating;
                    }
                  )
                  e.ratingvalue = sumRating;
                }
              )
            }
          }
        }
      )
  }

  getEntiesCity(): void {
    this.userService.getCityData().subscribe(
      (response) => {
        this.cities = response.data;
      }
    )
  }

  getWeekEntriesByStatus(item): void {
    let userRole = localStorage.getItem('userRole');
    let user = localStorage.getItem('userId');
    // if (parseInt(userRole) === 70) {
    //   this.allWeeksEntries = this.allWeeksEntries.filter(w => w.user_id == parseInt(user));
    // }
    console.log(typeof (item))
    switch (item) {
      case '1':
        this.weekEntries = this.allWeeksEntries;
        break;
      case '2':
        this.weekEntries = this.allWeeksEntries.filter(e => e.moderator_internal_status == 4);
        break;
      case '3':
        this.weekEntries = this.allWeeksEntries.filter(e => e.status == 2);
        break;
      case '5':
        this.weekEntries = this.allWeeksEntries.filter(e => e.moderator_internal_status == 6);
        break;
      case '4':
        this.weekEntries = this.allWeeksEntries.filter(e => e.moderator_internal_status == 9);
        break;
      case '6':
        this.weekEntries = this.allWeeksEntries.filter(e => e.moderator_internal_status == 3);
        break;
    }
  }

  getEntiesWeeks(): void {
    this.userService.getWeeks().subscribe(
      (response) => {
        this.weeks = response;
      }
    )
  }

  getEntiesPosition(): void {
    this.userService.getPositionData().subscribe(
      (response) => {
        this.position = response;
      }
    )
  }

  getEntriesCategory(): void {
    this.userService.getCategory().subscribe(
      (response) => {
        this.category = response;
      }
    )
  }

  getRatingOfEntries(): void {
    this.userService.getRatingEntries().subscribe(
      (response) => {
        this.ratings = response;
      }
    )
  }

  getRatingParameter(): void {
    this.userService.getRatingParameter().subscribe(
      (response) => {
        this.ratingParameters = response;
        this.ratingParameters.forEach(
          r => {
            r.paramter_id == r.id;
          }
        )
      }
    )
  };

  getSelectedEntryById(id: any) {
    this.selectedEntries = this.weekEntries.find(w => w.id == id);
    return this.selectedEntries;
  }

  getCityBasedRecord(cityId) {
    if (cityId != -1) {
      this.weekEntries = this.allWeeksEntries.filter(w => w.city_id == parseInt(cityId));
    }
  }

  getRankBasedRecord(rank) {
    this.weekEntries = this.allWeeksEntries.filter(w => w.rank == parseInt(rank));
  }

  getDateBasedRecords(date1, date2) {
    // this.weekEntries = this.allWeeksEntries.filter(w => (new Date(w.submitted_date) >= new Date(date1)) && (new Date(w.submitted_date) <= new Date(date2)))
    // console.log(this.weekEntries);
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate()+1);
    this.weekEntries = this.allWeeksEntries.filter(
      m => new Date(m.submitted_date) >= new Date(date1) && new Date(m.submitted_date) <= date2
    );
  }
}
